.bg {
    background-image: url("/src/img/cover.jpg");
}

body, html {
    font-family: 'Inter', sans-serif;
  }
 
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
.fade-in {
    animation: fadeIn 1s ease-in-out; /* Change the duration or animation style as per your preference */
  }
  
@tailwind base;
@tailwind components;
@tailwind utilities;